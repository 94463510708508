import React from "react";
import "./Navbar.css";
import "../../index.css"
import { Link, NavLink } from "react-router-dom";
import $ from 'jquery';
import Doctolib from '../../assets/icon_doctolib.png';

// navigation du site

export default function Navbar() {


  $(document).ready(function () {
    $('.js-scrollTo').on('click', function () { 
      var page = $(this).attr('href'); 
      var speed = 750; 
      $('html, body').animate({ scrollTop: $(page).offset().top }, speed); // Go
      return false;
    });
  });
  const toggle = () => {
    var sec = document.getElementById('sec');
    var nav = document.getElementById('navigation');
    sec.classList.toggle('active');
    nav.classList.toggle('active');
  }
  function topFunction() {
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0; 
  }


  return (
    <div>
      <div className="banner" id="sec">
        <header>
          <Link className="adress-header"
            to="/contact"
          >11 rue de la Grande Chaumière - 75006 Paris</Link>
          <div id="toggle" onClick={toggle}></div>
        </header>
      </div>
      <div id="navigation">
        <ul>
          <li className="js-scrollTo">
            <Link
              to="/"
            >accueil</Link>
          </li>
          <li className="js-scrollTo">
            <Link
              to="/actualites"
            >actualités</Link>
          </li>
          <li className="js-scrollTo">
            <Link
              to="/contact"
            >contact</Link>
          </li>
        </ul>
      </div>

      <div className="nav-info">
        <div className="nav-info-centrage">
          <div className="nav-info-logo">
            <Link
              to="/"
            ><h4>celine brocheray</h4></Link>
            <p>Cabinet de Psychothérapie à Paris 6e arrondissement</p>
          </div>
          <div className="nav-info-contact">
            <ul>
              <li>
                <a href="tel:+33188248332" className="tel">06 11 58 91 26</a>
              </li>
              <li>
                <Link
                  className="contact-btn lid-btn"
                  to="/contact"
                >Contactez-moi</Link>
              </li>
              <li>
                <a
                  href="https://www.doctolib.fr/psychotherapeute/paris/celine-brocheray"
                  target="_blank"
                  rel="noreferrer"
                  className="doctolib">Prendre un RDV sur
                  <span>
                    <img
                      src={Doctolib}
                      alt="logo doctolib"
                    />
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <nav className="menu">
        <ul>
          <li>
            <NavLink
              to="/enfant"
              className={({ isActive }) => isActive ? "activeLink" : ""}
              onClick={topFunction()}
            >consultation pour enfant</NavLink>
          </li>
          <li>
            <NavLink
              to="/adolescent"
              className={({ isActive }) => isActive ? "activeLink" : ""}
              onClick={topFunction()}
            >consultation pour adolescent</NavLink>
          </li>
          <li>
            <NavLink
              to="/adulte"
              className={({ isActive }) => isActive ? "activeLink" : ""}
              onClick={topFunction()}
            >consultation pour adulte</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}