import React from "react";
import "./NotFound.css";
import { Link } from "react-router-dom";

// Page non trouvée du site 

export default function NotFound() {

  return (
    <div>
      <div className="NotFound">
        <div id="error-page">
          <div className="error-page-content">
            <h2 className="error-page-header" data-text="404">
              404
            </h2>
            <h4 data-text="Opps! Page non trouvée">
              Opps! Page non trouvée
            </h4>
            <p>
              Désolé, la page que vous recherchez n'existe pas.
            </p>
            <div className="btns">
              <Link to="/">Retour</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
