import React from "react";
import Card from "../../Components/Card/Card";
import "./Actualites.css";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Actu1 from "../../assets/actuImg1.jpg";
import Memoire from "../../assets/MémoireAdolescent-CélineNguyenN°22010655.pdf"

const dummyData = [
  {
    id: 1,
    title: "La fabuleuse odyssée de l’adolescence, ou l’avènement subjectif du sujet",
    bodyTitle1: "",
    bodyTitle2: "",
    bodyTitle3: "",
    bodyContent1: "L’effervescence de l’adolescent au travers de son insurrection, ses passages à l’acte et ses passions, le place à un carrefour de la vie. Sa résistance butée, le porte à réinventer la langue, les technologies, l’art…Qui sait jusqu’où il embarquera avec lui le reste de l’humanité ? Quelle que soit l’époque, il semble que l’histoire se répète. La relation entre adultes et jeunes est par nature en tension, comme s’il se jouait une autre scène de l’ordre de l’inconscient collectif. Que nous raconte l’adolescent par sa résistance à son environnement, à ce qui caractérise l’humanité dans ses déterminismes, son aveuglement, sa résignation ?",
    bodyContent2: "",
    bodyContent3: "",
    date: "Le 30 octobre 2022",
    image: Actu1,
    link: Memoire,
  },
];


// Page Actualités du site 


export default function Actualites() {
  return (
    <div>
      <Navbar />
      <section className="actualites">
        <h1 className="home-title">Actualités</h1>

        <div className="container-cards">
          {dummyData.map((item) => {
            return (
              <Card key={uuidv4()}>
                <div className="img">
                  <img src={item.image} alt="" />
                </div>
                <div className="content">
                  <p>{item.date}</p>
                  <h2>{item.title}</h2>
                </div>
                <div className="link">
                  <Link
                    to={{
                      pathname: `articles/${item.title
                        .replace(/\s+/g, "-")
                        .trim()}`,
                    }}
                    state={{
                      title: item.title,
                      date: item.date,
                      link: item.link,
                      image: item.image,
                      bodyTitle1: item.bodyTitle1,
                      bodyTitle2: item.bodyTitle2,
                      bodyTitle3: item.bodyTitle3,
                      bodyContent1: item.bodyContent1,
                      bodyContent2: item.bodyContent2,
                      bodyContent3: item.bodyContent3,
                    }}
                  >
                    Voir cette actualité
                  </Link>
                </div>
              </Card>
            );
          })}
        </div>
      </section>
    </div>
  );
}
