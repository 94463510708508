import React from "react";
import "../Pages.css";
import "../../../index.css";
import Navbar from "../../../Components/Navbar/Navbar";
import psyAdulte1 from '../../../assets/psychologue-adulte1.jpg'
import psyAdulte2 from '../../../assets/psychologue-adulte2.jpg'
import Footer from "../../../Components/Footer/Footer";
import Relance from "../../../Components/Relance/Relance";
import Info from "../../../Components/Info/Info";


// Page Adulte du site

export default function Adulte() {
    return (
        <div>
            <Navbar />
            <section className="page">
                <div className="container">
                    <div className="title" data-aos="fade-up">
                        <h1>Consultation pour adulte</h1>
                        <div className="sub-title">
                            <p className="intro">
                                <strong>Faire appel à un psy près de chez vous </strong>
                                quand on se sent débordé par un trop plein de contraintes,
                                <strong> de stress, </strong>et <strong>d’émotions négatives</strong>
                                ; c’est reconnaître que tout seul on tourne en rond.
                                <strong> Psychothérapeute à Paris 6, entre Montparnasse et le Jardin du Luxembourg</strong>
                                , je vous reçois pour vous aider à dépasser ces difficultés.
                            </p>
                        </div>
                    </div>

                    <div className="double-colonne">
                        <div className="text" data-aos="fade-up" data-aos-delay="500">
                            <h2>Comment se passe une psychothérapie ?</h2>
                            <p>
                                Je vous propose au préalable une première consultation de prise de contact permettant d’explorer ensemble les raisons qui vous emmènent à consulter. Elle me permet également de vous expliquer comment je travaille. Cette première séance est essentielle pour voir si vous vous sentez en confiance, car ce ne sera pas sans effets pour la suite du travail et les résultats attendus. Il est important de <strong>bien choisir son psy,</strong> le praticien qui vous convient qu’il soit psychologue, psychothérapeute ou psychanalyste. La suite des séances se tient dans un <strong>cadre confidentiel et protégé</strong>; et selon des <strong>règles à respecter :</strong> La régularité et le rythme des séances, la ponctualité, l’implication et l’investissement lors des séances, s’acquitter du coût des séances
                            </p>
                            <p>
                                C’est un espace et un moment où vous pourrez livrer et peut-être vous délivrer de ces souffrances. Lors des consultations, le psychothérapeute soutient ses patients dans une écoute active et bienveillante. Il ne juge pas les propos de ses patients et ne porte aucun à priori sur ce qui anime et agite l’individu, parfois à son insu dans son inconscient. De cette singularité, le patient pourra alors en faire une force dès lors qu’elle peut être pensée, reconnue et verbalisée. Ce <strong>travail psychique de vérité </strong>demande du courage et peut permettre dans certains cas, de dissoudre le symptôme dans le discours.
                            </p>
                        </div>
                        <div className="first image" data-aos="fade-left" data-aos-delay="1000">
                            <img
                                src={psyAdulte1}
                                alt="bilan psychologique pour enfant"
                            />
                        </div>
                    </div>
                    <Info />

                    <div className="double-colonne">
                        <div className="second image" data-aos="fade-right">
                            <img
                                title="bilan psychologique pour enfant"
                                src={psyAdulte2}
                                alt="bilan psychologique pour enfant"
                            />

                        </div>
                        <div className="text" data-aos="fade-up" data-aos-delay="500">
                            <h2>L’alliance thérapeutique</h2>
                            <p>
                                C'est <strong>un travail sur soi</strong> pour lequel vous décidez pour vous-même de votre rythme d’avancement, de ce que vous souhaitez dire ou pas, mais également de la durée de la thérapie, en lien avec le thérapeute. C’est de l’ordre d’une alliance. Il ne s’agit pas de vous forcer à l’aveu et il n’y a rien qui ne soit imposé, si ce n’est les règles énoncées dans le cadre de la cure thérapeutique. Il s’agit de partir de là où en est le patient, c’est-à-dire du <strong>désir du patient</strong>, et de ses aspirations.
                            </p>
                            <p>
                                Une psychothérapie n’est pas comme une psychanalyse où le patient s’allonge sur le divan. En psychothérapie, le patient est face au thérapeute dans un <strong>dialogue libre et spontané</strong>. C’est ce <strong>lien particulier avec votre psy</strong> qui vous permettra de vous engager dans ce travail personnel de soi (observation) et sur soi (transformation). La durée moyenne d’une psychothérapie peut aller de quelques semaines à quelques mois, en fonction des difficultés à traverser.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Relance />
            <Footer />
        </div>
    );
}
