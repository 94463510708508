import React from "react";
import "../Pages.css";
import "../../../index.css";
import EnfantJoyeux from '../../../assets/enfant-joyeux.jpg'
import psyEnfant2 from '../../../assets/psychologue-enfant2.jpg'
import Navbar from "../../../Components/Navbar/Navbar";
import Footer from "../../../Components/Footer/Footer";
import Relance from "../../../Components/Relance/Relance";
import Info from "../../../Components/Info/Info";


// Page Enfant du site 

export default function Enfant() {


    return (
        <div>
            <Navbar />
            <section className="page">
                <div className="container">
                    <div className="title" data-aos="fade-up">
                        <h1>Consultation pour enfant</h1>
                        <div className="sub-title">
                            <p className="intro">
                                La psychothérapie peut être une aide transitoire pour les enfants et leurs parents lorsque la famille est déstabilisée par certains troubles bruyants de l’enfant. Installée à
                                <strong> Paris 6</strong>, je me suis spécialisée dans la
                                <strong> psychothérapie pour enfant</strong>. Cette pratique requiert un sens de l’observation et une écoute infra-verbale, soit tous ces signes qui parlent à l’inconscient (formes, odeurs, couleurs).
                            </p>
                        </div>
                    </div>

                    <div className="double-colonne">
                        <div className="text" data-aos="fade-up" data-aos-delay="500">
                            <h2>Le travail thérapeutique avec l’enfant</h2>
                            <p>
                                L’accompagnement psychologique de l’enfant peut se faire<strong> dès l’âge de 5 ans</strong>
                                . Il est mené en lien avec l’un des parents voire les deux parents. Les troubles de l’enfant sont liés à son environnement familiale ou scolaire avec des évènements qui peuvent être plus ou moins difficiles à gérer :
                                <strong> l’entrée en maternelle, un déménagement, la naissance d’un frère ou d’une sœur dans la famille, la séparation du couple</strong>…N’ayant pas totalement accès au langage, l’enfant peut avoir des difficultés à penser et à verbaliser ses émotions.
                            </p>
                            <p>
                                La psychothérapie pour enfant s’appuie sur  <strong>une médiation au travers des jeux </strong> pour engager un échange et installer un lien entre le thérapeute et l’enfant. C’est dans le jeu (et sa mise en scène), que je peux repérer <strong>certains troubles du comportement </strong>comme l’opposition, la provocation,  l’agitation ou d’autres <strong>troubles d’ordre affectif</strong>. Cette observation psychologique est le point de départ du travail thérapeutique qui se construit avec les parents, afin d’élaborer des outils pour avancer au quotidien. Je travaille par ailleurs en réseau avec d’autres professionnels : pédopsychiatre, orthophoniste, psychomotricien…Et je peux vous recommander si besoin vers les <strong>institutions de la petite enfance, à proximité dans le 6 arrondissement de Paris.</strong>
                            </p>
                            <p>
                                <span className="citation">« A quoi ressemble l’enfant normal ? Ne fait-il que manger, grandir et sourire gentiment ? Non. Il n’est pas du tout ainsi. Un enfant normal, s’il a confiance dans son père et dans sa mère, essaie tout. En grandissant, il essaie à fond son pouvoir de briser, de détruire, de faire peur, d’user, de gaspiller, de soutirer et d’usurper ». Donald W. Winnicott </span>
                            </p>
                        </div>
                        <div className="first image" data-aos="fade-left" data-aos-delay="1000">
                            <img
                                src={EnfantJoyeux}
                                alt="bilan psychologique pour enfant"
                            />
                        </div>
                    </div>
                    <Info />
                    <div className="double-colonne">
                        <div className="second image" data-aos="fade-right">
                            <img
                                title="bilan psychologique pour enfant"
                                src={psyEnfant2}
                                alt="bilan psychologique pour enfant"
                            />

                        </div>
                        <div className="text second" data-aos="fade-up" data-aos-delay="500">
                            <h2>Les troubles psycho-affectifs chez l’enfant : peur de la séparation, phobie, terreurs nocturnes…</h2>
                            <p>
                                ‘‘Le portage’’ du psychothérapeute peut permettre de dénouer les situations les plus délicates : <strong>terreurs nocturnes, énurésie, intolérance à la frustration, peur de la séparation</strong> …Les parents se sentent souvent démunis face à la souffrance de leur enfant et se retrouvent eux-mêmes débordés par la situation. L’intervention d’un professionnel permet alors d’alléger la pression sur les parents qui ne sont plus seuls, dans ce face à face avec leur enfant. Elle ouvre vers de nouvelles possibilités d’une <strong>vie familiale plus sereine</strong>.
                            </p>
                            <p>
                                <span className="citation">
                                    « En naissant, un enfant transforme deux adultes en parents. On peut dire ainsi que c’est l’enfant qui fait les parents ». Françoise Dolto
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Relance />
            <Footer />
        </div>
    );
}
