import React from "react";
import "./Info.css";
import "../../index.css";



export default function Info() {
    return (
        <div>
            <div className="contact-tel" data-aos="fade-in">
                <div className="contact-container">
                    <p><strong>Informations pratiques</strong></p>
                    <p>Le cabinet est ouvert du lundi au samedi de 9h00 à 19h00 (sauf les mardis et jeudis)</p>
                    <p>Prise de rdv sur Doctolib - Tarifs : 70 – 90 € / séance</p>
                    <p>Consultations en présentiel et en distantiel - Durée des séances : 45 mn</p>
                    <p>
                    Vous souhaitez plus de précisions, n’hésitez pas à me contacter au <br/> 
                        <span>
                            <a className="tel" href="tel:+33188248332"> 06 11 58 91 26 </a>
                        </span>
                        pour un premier contact.
                    </p>
                </div>
            </div>
        </div>
    );
}



