import React from "react";
import "../Pages.css";
import "../../../index.css";
import Navbar from "../../../Components/Navbar/Navbar";
import psyAdo1 from '../../../assets/psychologue-adolescent1.jpg';
import psyAdo2 from '../../../assets/psychologue-adolescent2.jpg';
import Footer from "../../../Components/Footer/Footer";
import Relance from "../../../Components/Relance/Relance";
import Info from "../../../Components/Info/Info";

// Page Adolescent du site

export default function Adolescent() {
    return (
        <div>
            <Navbar />
            <section className="page">
                <div className="container">
                    <div className="title" data-aos="fade-up">
                        <h1>Consultation pour adolescent</h1>
                        <div className="sub-title">
                            <p className="intro">
                                L’adolescence est un moment de
                                <strong> bouleversement psychique et physique </strong>
                                qui peut mettre toute la famille sous tension,
                                <strong> tant la communication  entre parents et adolescents </strong>
                                est devenue difficile. L’adolescent est traversé par des émotions violentes et contradictoires qui peuvent l’amener à avoir
                                <strong> des conduites à risque</strong>
                                ,en attaquant parfois son corps.
                                <strong> Scarifications, troubles alimentaires, phobies, agressivité…</strong>, le
                                <span className="citation"> ‘‘symptôme ne serait qu’un compromis’’</span>
                                , selon Freud.
                            </p>
                        </div>
                    </div>

                    <div className="double-colonne">
                        <div className="text" data-aos="fade-up" data-aos-delay="500">
                            <h2>Être ado aujourd’hui</h2>
                            <p>
                                Avec le symptôme, l’individu tente de ménager une issue à ses pulsions refoulées. C’est une façon de régler un conflit psychique. En psychothérapie analytique, on considère que le symptôme apporte des bénéfices secondaires qu’il ne faut pas dénier, au risque de le déplacer vers un autre symptôme comme avec certains <strong>TOC (Troubles Obsessionnels Compulsifs)</strong>.
                            </p>
                            <p>
                                Il témoigne pour le jeune des impossibilités à <strong>faire face au réel</strong>. La question du <strong>sexuel, de l’identité, de l'image de soi </strong>
                                et bien d’autres sujets encore, viennent bousculer le Moi. L'adolescence est un temps de
                                <strong> construction narcissique</strong> avec des mouvements d'idéalisation et d'identification, pris dans l'image du corps. Il se caractérise par une forme de
                                <strong> fascination de l'image</strong>, qui est amplifiée par les écrans. C'est dans un mouvement paradoxal que l'ado élabore son identité, qu'il veut unique mais identique à ses pairs. 
                            </p>
                            <p>
                                <span className="citation">‘‘Si tout se prépare dans l’enfance, tout se joue à l’adolescence’’ Evelyne Kestemberg</span>
                            </p>
                        </div>
                        <div className="first image">
                            <img
                                src={psyAdo1}
                                alt="bilan psychologique pour enfant"
                                data-aos="fade-left"
                                data-aos-delay="1000"
                            />
                        </div>
                    </div>
                    <Info />
                    <div className="double-colonne">
                        <div className="second image" data-aos="fade-right">
                            <img
                                title="bilan psychologique pour enfant"
                                src={psyAdo2}
                                alt="bilan psychologique pour enfant"
                            />

                        </div>
                        <div className="text" data-aos="fade-up" data-aos-delay="500">
                            <h2>Les enjeux à l’adolescence</h2>
                            <p>
                                La position des <strong>parents en ‘‘pièce d’usure’’</strong> n’est pas facile dans ce contexte de <strong>crise adolescente</strong>. L’autorité de l’adulte est redéfinit selon un nouveau répertoire de lecture du monde. Ses liens et relations à ses pairs occupent une place centrale dans la construction psychique de l’adolescent.
                            </p>
                            <p>
                                Le psychothérapeute intervient ici depuis une place particulière qui n’est pas celle du copain, ni celle de l’éducateur et encore moins celle d’un parent. C’est de cette place particulière que le thérapeute peut mettre au travail l’adolescent pour l’aider à se libérer des tensions liées à ses peurs et retrouver un certain <strong>estime de soi.</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Relance />
            <Footer />
        </div>
    );
}
