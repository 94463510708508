import React from "react";
import { Link } from "react-router-dom";
import "./Galerie.css"
import AngeSansTete from "../../assets/statue-ange-sans-tete.jpg";
import Ange from "../../assets/statue-ange.jpg";
import Peinture from "../../assets/peinture-homme-femme.jpg";
import Encre from "../../assets/peinture-encre.jpg";
import Structure from "../../assets/structure-artistique.jpg";
import PeintureArtistique from "../../assets/peinture-artistique.jpg";
import HommeMusée from "../../assets/homme-musée.jpg";
import Tag from "../../assets/tag-joker-psychology.jpg";
import FilleFleurs from "../../assets/peinture-fille-fleurs.jpg";


// Composant Galerie du site

export default function Galerie() {

    return (
        <div>
            <div class="gallery" data-aos="fade-up">
                <div class="gallery-content">
                    <div class="gallery-container">
                        <div class="container">
                            <div class="gallery">

                                <Link
                                    to={AngeSansTete}
                                    className="img-1"
                                    target="_blank">
                                    <p>+</p>
                                </Link>
                                
                                <Link
                                    to={Ange}
                                    className="img-2"
                                    target="_blank">
                                    <p>+</p>
                                </Link>
                                
                                
                                
                                <Link
                                    to={Tag}
                                    className="img-3"
                                    target="_blank">
                                    <p>+</p>
                                </Link>

                                <Link
                                    to={Encre}
                                    className="img-4"
                                    target="_blank">
                                    <p>+</p>
                                </Link>
                                
                                <Link
                                    to={Structure}
                                    className="img-5"
                                    target="_blank">
                                    <p>+</p>
                                </Link>
                                <Link
                                    to={PeintureArtistique}
                                    className="img-6"
                                    target="_blank">
                                    <p>+</p>
                                </Link>
                                
                                <Link
                                    to={HommeMusée}
                                    className="img-7"
                                    target="_blank">
                                    <p>+</p>
                                </Link>
                                
                                <Link
                                    to={Peinture}
                                    className="img-8"
                                    target="_blank">
                                    <p>+</p>
                                </Link>
                                
                                <Link
                                    to={FilleFleurs}
                                    className="img-9"
                                    target="_blank">
                                    <p>+</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
