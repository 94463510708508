import React from "react";
import "./Contact.css";
import "../../index.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer"



export default function Contact() {
  return (
    <div>
      <div>
        <Navbar />
        <div className="fullpage">
          <div className="title" data-aos="fade-up">
            <h1>Contactez votre psychothérapeute à Paris 6</h1>
            <div className="sub-title">
              <p className="intro">
                Vous souhaitez avoir plus d'informations concernant mes services, ou bien prendre un rendez-vous ?
              </p>
            </div>
          </div>
          <div className="contact">
            <div className="container">
              <div className="content">
                <div className="boxs">
                  <div className="left" data-aos="fade-right" data-aos-delay="500">
                    <div className="info">
                      <div className="contact-info">
                        <h2>Céline Brocheray</h2>
                        <p>11 rue de la Grande Chaumière<br />
                          75006 PARIS</p>
                        <p>Métro : Vavin <br />
                          Rer : Port Royal</p>
                        <p>06 11 58 91 26</p>
                        <p>celine.brocheray[a]gmail.com</p>
                      </div>
                    </div>
                    <div className="map">
                      <iframe title="la map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.838833774965!2d2.328496415531962!3d48.842212779285944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e671cf477857c9%3A0x56974191aa7de967!2s11%20Rue%20de%20la%20Grande%20Chaumi%C3%A8re%2C%2075006%20Paris!5e0!3m2!1sfr!2sfr!4v1666764185355!5m2!1sfr!2sfr" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>

  );
}
