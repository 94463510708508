import React from "react";
import Cheque from "../../assets/icon-cheque.png";
import Euro from "../../assets/icon-monnaie-euro.png";
import "./Paiement.css";

export default function Paiement() {
  return (
    <div className="info-container" data-aos="fade-in">
      <div className="info-content">
        <div className="info-paiement">
          <h3>Moyens de paiements acceptés :</h3>
          <ul>
            <li>
              <div>
                <img src={Cheque} alt="icon cheque bancaire" />
              </div>
              <p>Chèques</p>
            </li>
            <li>
              <div>
                <img src={Euro} alt="icon piece de monnaie euro" />
              </div>
              <p>Espèces</p>
            </li>
          </ul>
        </div>
        <div className="info-mutuelle">
          <p>Remboursement possible auprès de certaines mutuelles</p>
        </div>
      </div>
    </div>
  );
}