import React from "react";
import "./Home.css";
import "../../index.css";
import Navbar from "../../Components/Navbar/Navbar";
import Slider from "../../Components/Slider/Slider";
import Paiement from "../../Components/Paiement/Paiement";
import Footer from "../../Components/Footer/Footer"
import Profil from "../../assets/celine-profil2.jpg";
import Cabinet1 from "../../assets/cabinet.jpg";
import Galerie from "../../Components/Galerie/Galerie";

// Page d'Accueil du site

export default function Home() {

  return (
    <div>
      <Navbar />
      <Slider />

      <section className="page home">
        <div className="container">
          <div className="title" data-aos="fade-up">
            <h1>Céline Brocheray, psychothérapeute pour enfants, adolescents et adultes à Paris 6e.</h1>
            <div className="sub-title">
              <p className="intro">
                <strong>Psychothérapeute à Paris 6e près de Montparnasse</strong>
                , je me suis spécialisée dans
                <strong> l’accompagnement psychologique d’enfants, d’adolescents et d’adultes</strong>
                . J’aide mes patients à traverser les périodes difficiles de leur vie comme les deuils, les ruptures, les transitions professionnelles ou tous autres épisodes de crises. Ce sont des  moments douloureux qui peuvent déclencher une véritable souffrance psychique et des troubles invalidants ( anxiété, épisodes dépressifs, troubles addictifs…). En s’engageant dans une thérapie, la personne
                <strong> enclenche un processus de changement </strong>
                qui ouvre vers de nouvelles possibilités.
              </p>
            </div>
          </div>

          <div className="double-colonne">
            <div className="first image profil">
              <img
                src={Profil}
                data-aos="fade-right"
                alt="bilan psychologique pour enfant"
              />
            </div>
            <div className="text" data-aos="fade-up">
              <h2><span className="citation"> ‘‘Je suis là où je ne pense pas et je pense là ou je ne suis pas’’ Jacques Lacan</span></h2>
              <p>
                Mon approche thérapeutique s’appuie sur les principes psychanalytiques de <strong>la cure de paroles, </strong>
                permettant de redonner du sens et de l’envie dans un quotidien devenu difficile. Il s’agit de comprendre les <strong>mécanismes inconscients </strong> qui sont à l’œuvre, pouvant entraîner un profond mal-être. Il s’exprime par des ruminations, des angoisses ou encore des blocages. Ces mécanismes inconscients sont intimement liés au parcours de vie de chacun, à ses traumatismes mais aussi à ses représentations inconscientes. C’est dans une relation de confiance et un échange authentique entre patient et thérapeute que peuvent se mettre en place des solutions pour retrouver une capacité d’action et un équilibre psychique.
              </p>
            </div>
          </div>
          <Paiement />
          <div className="double-colonne second">
            <div className="text second" data-aos="fade-up">
              <h2>A propos du titre de Psychothérapeute</h2>
              <p>
                Le titre de psychothérapeute est protégé par un décret de 2010. Son obtention est conditionnée par la validation d’un master 2 de psychologie ou de psychanalyste (formation universitaire de niveau bac+5). En complément, il doit justifier de 700 heures de pratique institutionnelle et d’une formation de psychopathologie clinique. Les psychothérapeutes agréés sont inscrits au Registre National des Psychothérapeutes, tenu par l’Agence Régional de Santé (ARS).
              </p>
            </div>
            <div className="second image">
              <img
                title="bilan psychologique pour enfant"
                src={Cabinet1}
                alt="bilan psychologique pour enfant"
                data-aos="fade-left"
              />
            </div>
          </div>
          <Galerie />
        </div>
      </section>
      <Footer />
    </div >
  );
}
