import React from 'react'
import FemmeChamp from "../../assets/femme_champ.jpg";
import HommeJoyeux from '../../assets/homme-joyeux.jpg'
import Oeufs from '../../assets/oeufs-dessines.jpg'
import "../../index.css";
import './Slider.css'

export default function Slider() {
    var counter = 1;
    setInterval(function(){
      document.getElementById('radio' + counter).checked = true;
      counter++;
      if(counter > 4){
        counter = 1;
      }
    }, 4000);
  return (
    <div>
      <div className='slider-img'>
          <div className="slider">
          <div className="slides">
          <input type="radio" name="radio-btn" id="radio1"/>
          <input type="radio" name="radio-btn" id="radio2"/>
          <input type="radio" name="radio-btn" id="radio3"/>

          <div className="slide first">
              <img src={Oeufs} alt="jeu scrabble"/>
          </div>
          <div className="slide second">
              <img src={FemmeChamp} alt="homme souriant assis sur un canapé"/>
          </div>
          <div className="slide third">
              <img src={HommeJoyeux} alt="boite à oeufs déssinnés en visages"/>
          </div>
          
          <div className="navigation-auto">
              <div className="auto-btn1"></div>
              <div className="auto-btn2"></div>
              <div className="auto-btn3"></div>
          </div>
          </div>
          <div className="navigation-manual">
          <label htmlfor="radio1" className="manual-btn"></label>
          <label htmlfor="radio2" className="manual-btn"></label>
          <label htmlfor="radio3" className="manual-btn"></label>
          </div>
      </div>
    </div>
  </div>
  )
}