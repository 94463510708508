import React from "react";
import "./Footer.css";
import "../../index.css";
import { Link } from "react-router-dom";



export default function Footer() {
    return (
        <div>
            <footer>
                <div className="footer-container">
                    <div className="footer-logo">
                        <Link
                            to="/" className="logo">
                            <span className="name-logo">CELINE BROCHERAY</span>
                            <span className="baseline">Psychothérapeute à Paris 6e arrondissement</span>
                        </Link>
                    </div>
                    <div className="footer-infos">
                        <p className="tel">06 11 58 91 26</p>
                        <Link
                            to="/contact"
                            className="adresse-footer">
                            11 rue de la Grande Chaumière <br />
                            75006 PARIS
                        </Link>
                    </div>
                </div>
            </footer>
        </div>
    );
}
