import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom"
import Home from './Containers/Home/Home'
import NotFound from './Containers/NotFound/NotFound';
import Contact from './Containers/Contact/Contact';
import Enfant from "./Containers/Pages/Enfants/Enfant";
import Adolescent from "./Containers/Pages/Adolescent/Adolescent";
import Adulte from "./Containers/Pages/Adulte/Adulte";
import Actualites from "./Containers/Actualites/Actualites";
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import Article from "./Containers/Article/Article";



function App() {
  useEffect(() => {
    Aos.init({duration: 1000});
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/enfant" element={<Enfant />}></Route>
        <Route path="/adolescent" element={<Adolescent />}></Route>
        <Route path="/adulte" element={<Adulte />}></Route>
        <Route path="/actualites/articles/:slug" element={<Article />}></Route>
        <Route path="/actualites" element={<Actualites />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </div>
  );
}

export default App;
