import React from "react";
import "./Relance.css";
import "../../index.css";
import { Link } from "react-router-dom";


export default function Relance() {
    return (
        <div>
            <div className="relance-container" data-aos="fade-up">
                <div className="relance-title">
                    <p>Consultez également :</p>
                </div>
                <nav className="relance-menu">
                    <ul>
                        <li>
                            <Link
                                to="/enfant"
                            >psychologue pour enfant</Link>
                        </li>
                        <li>
                            <Link
                                to="/adolescent"
                            >psychologue pour adolescent</Link>
                        </li>
                        <li>
                            <Link
                                to="/adulte"
                            >psychologue pour adulte</Link>
                        </li>
                    </ul>
                </nav>

            </div>

        </div>
    );
}
